import { Navigation } from "swiper/modules"
import { Swiper } from "swiper/react"
import CardSectionTitle from "../CardSectionTitle/CardSectionTitle"
import HorizontalScrollDiv from "src/utils/helpers/HorizontallScroll"

interface CarouselProps {
  title: string
  icon?: string
  children: any
  query?: string
}
const Carousel = ({ title, icon, children, query }: CarouselProps) => {
  return (
    <>
      {window.innerWidth > 736 ? (
        <Swiper
          modules={[Navigation]}
          style={{ paddingBottom: "56px" }}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next"
          }}
          spaceBetween={20}
          breakpoints={{
            974: {
              slidesPerView: 4
            },
            768: {
              slidesPerView: 3
            },
            320: {
              slidesPerView: 1
            },
            0: {
              slidesPerView: 2
            }
          }}
        >
          <span slot="container-start">
            <CardSectionTitle icon={icon} title={title} query={query} />
          </span>
          {children}
        </Swiper>
      ) : (
        <>
          <span>
            <CardSectionTitle icon={icon} title={title} />
          </span>
          <HorizontalScrollDiv className="md:px-auto no-scrollbar relative flex select-none gap-0 px-4 pb-10 pt-2">
            {children}
          </HorizontalScrollDiv>
        </>
      )}
    </>
  )
}

export default Carousel
