import Image from "next/image"
import React from "react"
import Typography from "src/views/components/typography"
import { CardSectionTitleBox, CardSectionTitleLeft, CardSectionTitleRight } from "./CardSectionTitle.styles"
import Link from "next/link"
import ArrowButton from "src/views/components/common/Button/ArrowButton"
import { useTranslation } from "next-i18next"

interface Props {
  icon?: string
  query?: string
  title: string
  onClick?: () => void
}

const CardSectionTitle = ({ icon, title, query }: Props) => {
  const { t } = useTranslation("common")

  return (
    <CardSectionTitleBox className="md:px-auto px-4">
      <CardSectionTitleLeft>
        {icon && <Image src={icon} alt={icon} width={40} height={40} />}
        <Typography type="h4">{title}</Typography>
      </CardSectionTitleLeft>

      <CardSectionTitleRight>
        <Typography
          type="text"
          className="custom-underline cursor-pointer	text-black underline hover:text-primary-100 hover:no-underline"
        >
          {title === t("BEST_COMPANIES") ? (
            <Link href="/companies">{t("ALL_COMPANY")}</Link>
          ) : title === t("BLOG") ? (
            <Link href="https://blog.myjobs.ge/" target="blank">
              {t("ALL_BLOG")}
            </Link>
          ) : (
            <Link href={`/vacancy?${query}`}>{t("ALL_VACANCY")}</Link>
          )}
        </Typography>
        <ArrowButton variant="left" className="prev" />
        <ArrowButton variant="right" className="next" />
      </CardSectionTitleRight>
    </CardSectionTitleBox>
  )
}

export default CardSectionTitle
